<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot />
      </span>
    </template>
    {{ tooltip }}
  </v-tooltip>
</template>

<script>
  // Permet de simplifier l'utilisation du composant tooltip
  export default {
    name: 'SimpleTooltip',
    props: {
      tooltip: String
    }
  }
</script>
