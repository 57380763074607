<template>
  <v-card class="elevation-x">
    <v-list class="pa-0 " three-line>
      <q-infinite-scroll ref="is" :disabled="endReached" @load="load">
        <template v-for="(item, index) in items">
          <user-list-item :key="item" :user="user(membership(item).user)" go-to-profile>
            <template v-if="membership(item).user !== $auth.user.sub" #actions>
              <simple-tooltip v-if="membership(item).elevation === 'member' && $can('members:update_elevation', space)" x-small tooltip="Promouvoir manager">
                <v-btn icon :loading="loading.promote" @click.stop="promoteMember(membership(item))">
                  <font-awesome-icon fixed-width size="lg" :icon="['fal', 'arrow-up']" class="primary--text" />
                </v-btn>
              </simple-tooltip>

              <simple-tooltip v-if="membership(item).elevation === 'manager' && $can('members:update_elevation', space)" x-small tooltip="Retirer le rôle de manager">
                <v-btn icon :loading="loading.demote" @click.stop="demoteMember(membership(item))">
                  <font-awesome-icon fixed-width size="lg" :icon="['fal', 'arrow-down']" class="primary--text" />
                </v-btn>
              </simple-tooltip>


              <simple-tooltip v-if="$can('transfer_ownership', space)" x-small tooltip="Transferer propriété">
                <v-btn icon :loading="loading.transfer" @click.stop="transferOwnership(membership(item))">
                  <font-awesome-icon fixed-width size="lg" :icon="['fal', 'people-arrows']" class="primary--text" />
                </v-btn>
              </simple-tooltip>

              <simple-tooltip v-if="$can('members:remove', space)" x-small tooltip="Retirer de l'espace">
                <v-btn icon :loading="loading.remove" @click.stop="removeMember(membership(item))">
                  <font-awesome-icon fixed-width size="lg" :icon="['fal', 'times']" class="red--text text--darken-4" />
                </v-btn>
              </simple-tooltip>
            </template>
          </user-list-item>
          <v-divider v-if="index + 1 < items.length" :key="'divider-'+index" class="my-0" />
        </template>

        <template v-slot:loading>
          <skeleton-list :small="items.length > 0" />
        </template>
      </q-infinite-scroll>
    </v-list>
  </v-card>
</template>

<script>
  import UserListItem from '@/modules/core/layout/UserListItem'
  import SkeletonList from '@/modules/core/layout/SkeletonList'
  import SimpleTooltip from '@/modules/core/layout/SimpleTooltip'
  import {QInfiniteScroll} from 'quasar'
  import {mapActions, mapMutations, mapState} from 'vuex'
  import viewProfile from '@/mixins/navigationMethods'
  import {Membership, Person, Space} from '@/models'
  import * as Members from '@/modules/spaces/api/members'

  export default {
    name: 'SpaceMembers',
    components: {SkeletonList, UserListItem, QInfiniteScroll, SimpleTooltip},
    mixins: [viewProfile],
    data() {
      return {
        loadingPage: true,
        loading: {
          promote: false,
          demote: false,
          transfer: false,
          remove: false
        }
      }
    },
    computed: {
      ...mapState('spaces/members', ['items', 'page', 'total', 'endReached']),
      userMembership() {
        return Membership.get(this.space.membership)
      },
      membership() {
        return uid => Membership.get(uid)
      },
      user() {
        return uid => Person.get(uid)
      },
      space() {
        return Space.get(this.$store.state.core.space)
      }
    },
    created(){
      this.$root.$on('space:add_member', this.reset)
    },
    beforeDestroy() {
      this.$root.$off('space:add_member', this.reset)
    },
    methods: {
      ...mapActions('spaces/members', ['fetchNextPage']),
      ...mapMutations('spaces/members', ['removeItem', 'resetState']),
      async load(index, done) {
        this.loadingPage = true
        await this.fetchNextPage()
        this.loadingPage = false
        done(this.endReached)
      },
      reset() {
        this.resetState()
        this.$refs.is.resume()
      },
      async removeMember(membership) {
        this.loading.remove = true
        try {
          await Members.remove(this.$store.state.core.space, membership.user)
          this.reset()
          this.loading.remove = false
        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.loading.remove = false
        }
      },
      async promoteMember(membership) {
        this.loading.promote = true
        try {
          await Members.updateElevation(this.$store.state.core.space, membership.user, 'manager')
          this.reset()  // On reset aussi en cas de changement d'elevation car la liste est d'abord trié par élévation
          this.loading.promote = false
        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.loading.promote = false
        }
      },
      async demoteMember(membership) {
        this.loading.demote = true
        try {
          await Members.updateElevation(this.$store.state.core.space, membership.user, 'member')
          this.reset() // On reset aussi en cas de changement d'elevation car la liste est d'abord trié par élévation
          this.loading.demote = false
        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.loading.demote = false
        }
      },
      async transferOwnership(membership) {
        this.loading.transfer = true
        if (!this.userMembership || this.userMembership.elevation !== 'owner') {
          this.$handleApiError(new Error('Expect current user to be owner of the space'))
          return
        }

        let targetUser = Person.get(membership.user)
        if (!await this.$confirm(`Êtes-vous sûr de vouloir nommer "${targetUser.full_name}" responsable de l'espace à votre place`)) {
          this.loading.transfer = false
          return
        }

        try {
          await Members.transferOwnership(this.$store.state.core.space, membership.user)

          this.userMembership.$mutate(membership => {
            membership.elevation = 'member'
          })

          this.reset()  // On reset aussi en cas de changement d'elevation car la liste est d'abord trié par élévation
          this.loading.transfer = false
        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.loading.transfer = false
        }
      },
      goToProfile() {
        if(this.user.type ==='user:professional'){
          this.$router.push({name: 'professional-profile', params: {uid: this.user.uid}})
        }else {
          this.$router.push({name: 'patient-profile', params: {uid: this.user.uid}})
        }

      }
    }
  }
</script>

